import React from 'react';
import clsx from 'clsx';

// prop types
interface ServiceCardProps {
  href?: string;
  isExternalUrl?: boolean;
  color?: 'white' | 'primary' | 'secondary' | 'orangeTwine' | 'brownSorell' | 'greenSmoke' | 'redRust' | 'lavender'
  imgSrc?: string;
  imgWidth?: number;
  imgHeight?: number;
  imgAlt?: string;
  title?: string;
  subTitle?: string;
  type?: string;
  className?: string;
  strings?: any;
}

export default function ServiceCard({
  href,
  isExternalUrl = false,
  color = 'primary',
  title,
  imgSrc,
  imgWidth,
  imgHeight,
  imgAlt,
  subTitle,
  type,
  className,
  strings,
}: ServiceCardProps) {
  const classes = clsx(
    'service-card',
    `service-card--${color}`,
    className
  )
  return (
    <article className={classes} data-type={type}>
      {href && <a href={href} target={isExternalUrl ? '_blank' : undefined} rel={isExternalUrl ? 'noopener noreferrer' : undefined} className='absolute-link service-card__link'><span className='sr-only'>{strings?.readMoreAbout} {title}</span></a>}
      {imgSrc && <img src={imgSrc} className="service-card__icon" alt={imgAlt} width={imgWidth} height={imgHeight} aria-hidden="true" />}
      <div className="service-card__content">
        {title && <h3 className="service-card__title">{title}</h3>}
        {subTitle && <p className="service-card__subtitle">{subTitle}</p>}
      </div>
    </article>
  );
}
