import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';
import LabelText from '../LabelText';
import { ArrowRight } from 'lucide-react';

// icons


// prop types
interface NewsCard {
    layout?: 'vertical' | 'horizontal';
    href?: string;
    isExternalUrl?: boolean;
    buttonText?: string;
    imgSrc?: string;
    imgSrcMedium?: string;
    imgSrcSmall?: string;
    imgAlt?: string;
    imgWidth?: number;
    imgHeight?: number;
    labelText?: string;
    labelBulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6';
    title?: string;
    type?: string;
    className?: string;
    strings?: any;
}

export default function NewsCard({
    layout = 'vertical',
    href,
    isExternalUrl = false,
    buttonText,
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    imgHeight,
    labelText,
    labelBulletColor,
    title,
    type,
    className,
    strings,
}: NewsCard) {
    const classes = clsx(
        'news-card',
        `news-card--${layout}`,
        className
    )
    return (
        <article className={classes} data-type={type}>
            {imgSrc && (
                <figure className="news-card__figure">
                    <Image
                        src={imgSrc}
                        width={imgWidth}
                        height={imgHeight}
                        srcMedium={imgSrcMedium}
                        srcSmall={imgSrcSmall}
                        alt={imgAlt}
                        sizes="(max-width: 768px) 100vw"
                    />
                </figure>
            )}
            <div className="news-card__content">
                {labelText && (
                    <LabelText className="news-card__label" bulletColor={labelBulletColor} text={labelText} />
                )}
                <div className="news-card__title-wrapper">
                    {title && <h3 className="news-card__title">{title}</h3>}
                    {buttonText && (
                        <a href={href} target={isExternalUrl ? '_blank' : undefined} rel={isExternalUrl ? 'noopener noreferrer' : undefined} className="news-card__link">
                            {buttonText}
                            <ArrowRight />
                            <span className="sr-only">{strings?.readMoreAbout} {title}</span>
                            <span className='absolute-link'></span>
                        </a>
                    )}
                </div>
            </div>
        </article>
    );
}
